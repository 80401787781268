<template>
	<div>
		<hero class="mb-6" title="Course Search" :subtitle="subtitle" />
		<sorted-courses :search="query">Search Results</sorted-courses>
		<b-container >
			<b-row>
				<b-col>
					<heading small>FEATURED COURSES</heading>
				</b-col>
			</b-row>
			<b-row class="mb-7">
				<featured-course :item="$store.state.courses[0]"></featured-course>
				<featured-course :item="$store.state.courses[1]"></featured-course>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import FeaturedCourse from '../components/ui/FeaturedCourse.vue'
export default {
	name: 'SearchResults',
	components: {
		'featured-course': FeaturedCourse
	},
	computed: {
		query() {
			return this.$route.query.search
		},
		subtitle() {
			return this.query 
				? `Results for your search "${this.query}"`
				: null
		}
	}
}
</script>
